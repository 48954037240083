import classnames from 'classnames';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

import * as styles from './ExternalLink.module.scss';

const ExternalLink = ({
  children = null,
  className = undefined,
  icon = <FiExternalLink />,
  ...props
}) => {
  const linkClasses = classnames(styles.iconLink, className);
  return (
    <a
      {...props}
      className={linkClasses}
      rel="noopener noreferrer"
      target="_blank"
    >
      {!!icon && <span>{icon}</span>}
      <span>{children}</span>
    </a>
  );
};
export default ExternalLink;
