import React from 'react';
import ReactPlayer from 'react-player/file';

import * as styles from './VideoPlayer.module.scss';

type VideoPlayerProps = {
  posterImage: string;
  url: string;
};

const VideoPlayer = ({ posterImage, url }: VideoPlayerProps) => (
  <div className={styles.videoWrapper}>
    <ReactPlayer
      className={styles.videoPlayer}
      controls
      height="100%"
      light={posterImage}
      playing
      url={url}
      width="100%"
    />
  </div>
);

export default VideoPlayer;
