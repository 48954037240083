import classnames from 'classnames';
import React from 'react';

import { useStore } from '@lib/hook';
import actions from '@lib/store/actions';

import * as styles from './NavToggleButton.module.scss';

const NavToggleButton = () => {
  const { appState, dispatch } = useStore();
  const { isOverlayOpen = false } = appState;
  const classNames = classnames(styles.button, {
    [styles.isX]: isOverlayOpen,
  });
  const handleClick = () => {
    dispatch({ action: actions.TOGGLE_OVERLAY, value: !isOverlayOpen });
  };
  return (
    <button
      aria-label="Toggle navigation overlay"
      className={classNames}
      onClick={handleClick}
    ></button>
  );
};

export default NavToggleButton;
