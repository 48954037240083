import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import LinkedData from './LinkedData';
import { PRODUCTION_DOMAIN } from '@lib/constants';
import { coalesce, ensureCanonicalUrl } from '@lib/util';
import { generateSocialMetaTags } from './util';

const MetaTags = ({ description, frontmatter, lang, meta = [], title }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            title
          }
        }
      }
    `
  );

  const pageTitle = coalesce([title, frontmatter.title]);
  const metaDescription = coalesce([
    description,
    frontmatter.title,
    site.siteMetadata.description,
  ]);
  const author = site.siteMetadata.author;
  const canonicalUrl = ensureCanonicalUrl(pathname);
  const metaItems = [
    ...generateSocialMetaTags({
      author,
      description: metaDescription,
      title: pageTitle,
    }),
    ...meta,
  ];

  const linkedDataProps = {};

  if (!isEmpty(frontmatter.breadcrumbs)) {
    linkedDataProps.breadcrumbs = frontmatter.breadcrumbs;
  }
  const titleTemplate =
    pathname === '/' ? '%s' : `%s | ${site.siteMetadata.title}`;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        meta={metaItems}
        title={pageTitle}
        titleTemplate={titleTemplate}
      >
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <LinkedData {...linkedDataProps} />
    </>
  );
};

MetaTags.defaultProps = {
  description: ``,
  frontmatter: {},
  lang: `en`,
  title: '',
};

MetaTags.propTypes = {
  description: PropTypes.string,
  frontmatter: PropTypes.object,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default MetaTags;
