import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './SectionHeading.module.scss';

const SectionHeading = ({ fullWidth = false, text }) => {
  const wrapperClassNames = classnames(styles.wrapper, {
    [styles.fullWidth]: fullWidth,
  });

  return (
    <div className={styles.sectionHeading}>
      <div className={wrapperClassNames}>
        <h3 className={styles.text}>{text}</h3>
      </div>
    </div>
  );
};

SectionHeading.propTypes = {
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
};

export default SectionHeading;
