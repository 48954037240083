import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';

import actions from '@lib/store/actions';
import { useStore } from '@lib/hook';

import * as styles from './SiteOverlay.module.scss';

const SiteOverlay = () => {
  const { appState, dispatch } = useStore();
  const { isOverlayOpen } = appState;
  const containerClassnames = classnames(styles.overlay, styles.activeBg, {
    [styles.isOpen]: isOverlayOpen,
  });
  const closeOverlay = useCallback(
    () => dispatch({ action: actions.TOGGLE_OVERLAY, value: false }),
    [dispatch]
  );
  const linkProps = {
    className: styles.link,
    onClick: closeOverlay,
  };
  return (
    <>
      {isOverlayOpen && (
        <Helmet>
          <body className={styles.overflowHidden} />
        </Helmet>
      )}

      <div className={containerClassnames}>
        <h2 className={styles.h2}>
          <Link {...linkProps} to="/">
            home
          </Link>
        </h2>

        <h2 className={styles.h2}>
          <Link {...linkProps} to="/startups">
            startups
          </Link>
        </h2>

        <h2 className={styles.h2}>
          <Link {...linkProps} to="/projects">
            projects
          </Link>
        </h2>

        <h2 className={styles.h2}>
          <Link {...linkProps} to="/cv">
            cv
          </Link>
        </h2>

        <h2 className={styles.h2}>
          <Link {...linkProps} to="/contact">
            contact
          </Link>
        </h2>
      </div>
    </>
  );
};

export default SiteOverlay;
