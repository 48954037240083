// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-2-mdx": () => import("./../../../src/pages/about2.mdx" /* webpackChunkName: "component---src-pages-about-2-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-company-volpe-center-mdx": () => import("./../../../src/pages/company/volpe-center.mdx" /* webpackChunkName: "component---src-pages-company-volpe-center-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-cv-mdx": () => import("./../../../src/pages/cv.mdx" /* webpackChunkName: "component---src-pages-cv-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-project-art-vr-mdx": () => import("./../../../src/pages/project/art-vr.mdx" /* webpackChunkName: "component---src-pages-project-art-vr-mdx" */),
  "component---src-pages-project-build-ball-mdx": () => import("./../../../src/pages/project/build-ball.mdx" /* webpackChunkName: "component---src-pages-project-build-ball-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-startup-cadre-mdx": () => import("./../../../src/pages/startup/cadre.mdx" /* webpackChunkName: "component---src-pages-startup-cadre-mdx" */),
  "component---src-pages-startup-freecause-mdx": () => import("./../../../src/pages/startup/freecause.mdx" /* webpackChunkName: "component---src-pages-startup-freecause-mdx" */),
  "component---src-pages-startup-gigwax-mdx": () => import("./../../../src/pages/startup/gigwax.mdx" /* webpackChunkName: "component---src-pages-startup-gigwax-mdx" */),
  "component---src-pages-startup-pradux-mdx": () => import("./../../../src/pages/startup/pradux.mdx" /* webpackChunkName: "component---src-pages-startup-pradux-mdx" */),
  "component---src-pages-startup-sensicast-mdx": () => import("./../../../src/pages/startup/sensicast.mdx" /* webpackChunkName: "component---src-pages-startup-sensicast-mdx" */),
  "component---src-pages-startup-verishop-mdx": () => import("./../../../src/pages/startup/verishop.mdx" /* webpackChunkName: "component---src-pages-startup-verishop-mdx" */),
  "component---src-pages-startups-mdx": () => import("./../../../src/pages/startups.mdx" /* webpackChunkName: "component---src-pages-startups-mdx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

