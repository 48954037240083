type GenerateSocialMetaTags = {
  author: string;
  description?: string;
  title: string;
};

export const generateSocialMetaTags = (props: GenerateSocialMetaTags) => {
  return [
    {
      name: 'description',
      content: `${props.description}`,
    },
    {
      property: 'og:description',
      content: `${props.description}`,
    },
    {
      property: 'og:site_name',
      content: 'Adam Bailin Portfolio',
    },
    {
      property: 'og:title',
      content: props.title,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: props.author,
    },
    {
      name: 'twitter:title',
      content: props.title,
    },
    {
      name: 'twitter:description',
      content: `${props.description}`,
    },
  ];
};
