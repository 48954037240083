// extracted by mini-css-extract-plugin
export var fullWidth = "Gallery-module--fullWidth--mqqas";
export var gallery = "Gallery-module--gallery--VKn_C";
export var flickWrapper = "Gallery-module--flickWrapper--3MQnG";
export var flickity = "Gallery-module--flickity--1BEx4";
export var imageContainer = "Gallery-module--imageContainer--2BeoK";
export var caption = "Gallery-module--caption--3LvZr";
export var dots = "Gallery-module--dots--Z7Q5X";
export var dot = "Gallery-module--dot--2tkhm";
export var dotSolid = "Gallery-module--dotSolid--1-oH2";
export var dotOpen = "Gallery-module--dotOpen--1qaza";
export var fadeIn = "Gallery-module--fadeIn--2hTLO";
export var slideIn = "Gallery-module--slideIn--3TMLI";
export var bgGradient = "Gallery-module--bgGradient--2RH3J";
export var borderColorStep = "Gallery-module--borderColorStep--1ecyH";