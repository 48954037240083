import React, { ReactNode } from 'react';

import routes from '../lib/routes';

export type CVItem = {
  description?: string | ReactNode;
  location?: string;
  slug?: string;
  title: string;
  years: string;
};

type CV = {
  education?: CVItem[];
  employment?: CVItem[];
};

export const cvData: CV = {
  education: [
    {
      description: (
        <>
          <h4>M.S. Transportation Engineering [&#x0200a;*&#x0200a;]</h4>
          <div>
            <em>
              (&#x0200a;* all but dissertation; completed coursework&#x0200a;)
            </em>
            <br />
            <br />
          </div>
          <div>
            Studied public transportation systems, traffic simulation, wireless
            vehicle communication networks.
          </div>

          <h4>B.S. Computer Systems Engineering</h4>
          <div>
            Studied engineering fundamentals, software, systems, and
            electronics.
            <br /> <br />
            Won 3rd place in our team's Senior Design Project,{' '}
            <em>Intelligent Parking System</em>.
            <br /> <br />
            TA for ECE 353/354 (year-long project-based microcontroller lab).
            <ul>
              <li>
                Programmed ATMega32 MCUs in Assembly and C; programmed Altera
                programmable logic devices in Verilog.
              </li>
              <li>Explored protocols and concepts like MIDI, PWM, and SPI.</li>
              <li>
                Integrated peripherals like light and sound sensors,
                piezoelectric sensors, motors, and CRT monitors.
              </li>
            </ul>
          </div>
        </>
      ),
      location: 'Amherst, MA',
      title: 'University of Massachusetts Amherst',
      years: '2003 - 2008',
    },
  ],
  employment: [
    {
      description: (
        <div>
          Developed web platforms for clients like Chopard &amp; Universal Music
          Group.
        </div>
      ),
      location: 'New York, NY',
      title: 'Consulting work',
      years: '2021',
    },
    {
      description: (
        <div>
          Early engineer creating Verishop's web platform, APIs, and internal
          tools.
        </div>
      ),
      location: 'Santa Monica, CA',
      slug: routes.VERISHOP,
      title: 'Verishop',
      years: '2018 - 2020',
    },
    {
      description: (
        <div>
          Early engineer helping to build and maintain Cadre's web platform and
          internal tools.
        </div>
      ),
      location: 'New York, NY',
      slug: routes.CADRE,
      title: 'Cadre',
      years: '2016 - 2018',
    },
    {
      description: (
        <div>
          Co-founded a DJ booking marketplace connecting DJs with gigs and
          facilitated payments between them. Features included browsing DJs and
          sampling their music, booking, messaging, payments, and reviews.
        </div>
      ),
      location: 'Brooklyn, NY',
      slug: routes.GIGWAX,
      title: 'Gigwax',
      years: '2014 - 2016',
    },
    {
      description: (
        <div>
          Pioneered shoppable photos that were embeddable anywhere on the web
          (tumblr whitelisted our domains). Built up the largest database of
          products shown on TV (100k+ over 100 TV shows). Integration with
          Shazam app.
        </div>
      ),
      location: 'New York, NY',
      slug: routes.PRADUX,
      title: 'Pradux',
      years: '2011 - 2016',
    },
    {
      years: '2010 - 2011',
      title: 'FreeCause',
      location: 'Boston, MA',
      description:
        'Software Engineer working on browser extensions, services, and affiliate loyalty programs for brands.',
    },
    {
      location: 'Cambridge, MA',
      slug: routes.VOLPE,
      title: 'Volpe Transportation Systems Center',
      years: '2008 - 2010',
      description: (
        <div>
          Software Engineer working in the Advanced Vehicle Technology, Crash
          Avoidance &amp; Advanced Saftey Systems Group.
          <br /> <br />
          Evaluated the effectiveness of safety systems installed in two dozen
          retrofitted vehicles driven by volunteers. Safety systems included
          lane change, lane departure, forward collision, and curve speed
          warnings.
        </div>
      ),
    },
    {
      location: 'Needham, MA',
      slug: routes.SENSICAST,
      title: 'Sensicast',
      years: '2003 - 2007',
      description: (
        <div>
          Worked over four years, spanning my summer and winter breaks through
          college. Provided general support and development for both hardware
          and software stacks.
        </div>
      ),
    },
  ],
};
