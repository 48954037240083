import classnames from 'classnames';
import { graphql, Link } from 'gatsby';
import Image from '@components/Image/Image';
import WelcomeMessage from '@components/Messages/WelcomeMessage';
import { PersonLinkedData } from '@components/MetaTags/LinkedData';
import SectionHeading from '@components/SectionHeading/SectionHeading';
import { getAnimationDelay } from '@lib/util';
import * as gridStyles from '@components/Grid/Grid.module.scss';
import * as styles from '@layouts/HomeLayout.module.scss';
import * as React from 'react';
export default {
  classnames,
  graphql,
  Link,
  Image,
  WelcomeMessage,
  PersonLinkedData,
  SectionHeading,
  getAnimationDelay,
  gridStyles,
  styles,
  React
};