import { Link } from 'gatsby';
import React from 'react';

import NavToggleButton from '@components/Button/NavToggleButton';
import { useStore } from '@lib/hook';
import actions from '@lib/store/actions';

import * as styles from './SiteHeader.module.scss';

const navItemLinkProps = {
  activeClassName: styles.active,
  className: styles.link,
};

const siteTitle = 'Adam Bailin';

const SiteHeader = () => {
  const { dispatch } = useStore();

  const toggleOverlay = value => {
    dispatch({ action: actions.TOGGLE_OVERLAY, value });
  };

  return (
    <header className={styles.siteHeader}>
      <div className={styles.container}>
        <Link
          className={styles.homeLink}
          onClick={() => toggleOverlay(false)}
          to="/"
        >
          <span>{siteTitle}</span>
        </Link>

        <div className={styles.wrap}>
          <div className={styles.navItems}>
            <ul className={styles.navLinks}>
              <li className={styles.navLink}>
                <Link {...navItemLinkProps} to="/startups">
                  startups
                </Link>
              </li>

              <li className={styles.navLink}>
                <Link {...navItemLinkProps} to="/projects">
                  projects
                </Link>
              </li>

              <li className={styles.navLink}>
                <Link {...navItemLinkProps} to="/cv">
                  cv
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.menuButton}>
            <NavToggleButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
