// extracted by mini-css-extract-plugin
export var root = "HomeLayout-module--root--2s_Jg";
export var fadeIn = "HomeLayout-module--fadeIn--35ORI";
export var content = "HomeLayout-module--content--1QyLk";
export var main = "HomeLayout-module--main--3bGTt";
export var fadeInChildren = "HomeLayout-module--fadeInChildren--3AEtp";
export var slideIn = "HomeLayout-module--slideIn--TP0On";
export var chunk = "HomeLayout-module--chunk--2Mf3g";
export var titleDesc = "HomeLayout-module--titleDesc--F0C6A";
export var bbLink = "HomeLayout-module--bbLink--6hxO2";
export var vrLink = "HomeLayout-module--vrLink--k8lea";
export var imageLink = "HomeLayout-module--imageLink--2yC5D";
export var light = "HomeLayout-module--light--1I78K";
export var workDetails = "HomeLayout-module--workDetails--PmOhk";
export var bgGradient = "HomeLayout-module--bgGradient--x6_Ro";
export var borderColorStep = "HomeLayout-module--borderColorStep--25SlK";