// extracted by mini-css-extract-plugin
export var overlay = "SiteOverlay-module--overlay--3k5Ak";
export var activeBg = "SiteOverlay-module--activeBg--o5rLm";
export var bgGradient = "SiteOverlay-module--bgGradient--1OBYS";
export var isOpen = "SiteOverlay-module--isOpen--O_Fz8";
export var h2 = "SiteOverlay-module--h2--3m1tr";
export var link = "SiteOverlay-module--link--1TGRJ";
export var overflowHidden = "SiteOverlay-module--overflowHidden--1oCbI";
export var fadeIn = "SiteOverlay-module--fadeIn--24GwT";
export var slideIn = "SiteOverlay-module--slideIn--3IDPc";
export var borderColorStep = "SiteOverlay-module--borderColorStep--3iTGB";