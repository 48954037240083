import { graphql } from 'gatsby';
import Gallery from '@components/Gallery/Gallery';
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
import * as React from 'react';
export default {
  graphql,
  Gallery,
  Spacer,
  styles,
  React
};