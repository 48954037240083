import classnames from 'classnames';
import React from 'react';

import * as styles from './Grid.module.scss';

const Container = ({ children, className }) => {
  return (
    <div className={classnames(styles.container, className)}>{children}</div>
  );
};

export default Container;
