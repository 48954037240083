import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Flickity from 'react-flickity-component';

import * as styles from './Gallery.module.scss';

import 'flickity/css/flickity.css';

const flickityOptions = {
  pageDots: false,
  setGallerySize: false,
  selectedAttraction: 0.03,
  friction: 0.35,
};

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      totalSlides: props.items.length,
    };
  }

  componentDidMount = () => {
    this.flickity.on('change', () => {
      console.log(`current index is ${this.flickity.selectedIndex}`);
      this.setState({ currentIndex: this.flickity.selectedIndex });
    });
  };

  generateBottomContent = () => {
    const { caption } = this.props;
    const { currentIndex, totalSlides } = this.state;
    const dots = Array(totalSlides)
      .fill()
      .map((_, i) =>
        currentIndex === i ? (
          <div className={styles.dotSolid} />
        ) : (
          <div className={styles.dotOpen} />
        )
      );

    return (
      <div className={styles.caption}>
        <div className={styles.dots}>{dots}</div>

        <span>
          Image {currentIndex + 1} of {totalSlides}
        </span>

        {caption && <span> &mdash; {caption}</span>}
      </div>
    );
  };

  render() {
    const { altText, aspectRatio, items } = this.props;

    const paddingTop = ((1 / aspectRatio) * 100).toFixed(2);

    return (
      <div className={styles.gallery}>
        <div className={styles.fullWidth}>
          <div
            className={styles.flickWrapper}
            style={{ paddingTop: `${paddingTop}%` }}
          >
            <Flickity
              className={styles.flickity}
              flickityRef={c => (this.flickity = c)}
              options={flickityOptions}
              static
            >
              {items.map((image, index) => (
                <GatsbyImage
                  alt={altText}
                  className={styles.imageContainer}
                  image={image}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                  key={index}
                  // onLoad={event => handleOnLoad(event)}
                />
              ))}
            </Flickity>
          </div>
        </div>

        {this.generateBottomContent()}
      </div>
    );
  }
}

export default Gallery;

// const handleOnLoad = e => {
//   this.flickity.reloadCells();
//   this.flickity.resize();
// };
