import React, { useReducer } from 'react';

import actions from './actions';
import AppContext from './context';
import initialState from './initialState';

const StoreProvider = ({ children }) => {
  const [appState, dispatch] = useReducer((prevState, dispatchMessage) => {
    const { action, value } = dispatchMessage;
    switch (action) {
      case actions.TOGGLE_OVERLAY:
        return {
          ...prevState,
          isOverlayOpen: !!value,
        };
      default:
        console.warn('reducer returning old state');
        return prevState;
    }
  }, initialState);

  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { StoreProvider };
