/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import React from 'react';

import Footer from '@components/Footer/Footer';
import Container from '@components/Grid/Container';
import MetaTags from '@components/MetaTags/MetaTags';
import SiteHeader from '@components/SiteHeader/SiteHeader';
import SiteOverlay from '@components/SiteOverlay/SiteOverlay';
import { StoreProvider } from '@lib/store/reducer';

// some sites have this inside of gatsby-browser.js
import * as styles from './HomeLayout.module.scss';
import '../styles/global.scss';

// import './util/fonts';

const HomeLayout = ({ children, pageContext = {} }) => {
  const { frontmatter } = pageContext;

  return (
    <>
      <StoreProvider>
        <div className={styles.root}>
          <div className={styles.content}>
            <SiteHeader />

            <main className={styles.main}>
              <Container>{children}</Container>
            </main>

            <SiteOverlay />
          </div>
          <Footer />
        </div>
      </StoreProvider>

      <MetaTags frontmatter={frontmatter} />
    </>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeLayout;
