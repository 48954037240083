import { Link } from 'gatsby';
import CVRow from '@components/CVRow/CVRow';
import { cvData } from '@data/cvData';
import * as styles from '@styles/pages/cv.module.scss';
import * as React from 'react';
export default {
  Link,
  CVRow,
  cvData,
  styles,
  React
};