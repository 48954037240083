import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { PRODUCTION_DOMAIN } from './constants';

const animationDelayIncrement = 0.07;
const animationDelayStart = 0;

export const getAnimationDelay = (stage = 0) => ({
  style: {
    animationDelay: `${animationDelayStart + stage * animationDelayIncrement}s`,
  },
});

/** return first non-empty string */
export const coalesce = (stringArray: string[] = []) =>
  find(stringArray, s => !isEmpty(trim(s)));

const isRelativePath = (uri: string) => uri.startsWith('/');

export const ensureCanonicalUrl = (uri: string) => {
  if (isRelativePath(uri)) {
    return new URL(uri, `https://${PRODUCTION_DOMAIN}`);
  }
  return uri;
};
