import classnames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import * as styles from './Messages.module.scss';

// focused on product
// Product-focused software engineer based in NYC/LA,

const WelcomeMessage = () => (
  <div className={classnames(styles.message, styles.welcomeMessage)}>
    <p>
      <strong>Welcome to my portfolio</strong> &mdash; I'm an engineer with lots
      of interests including software, design, technology, and startups.{' '}
      <Link className={styles.welcomeLink} to="/cv">
        more
      </Link>{' '}
      &raquo;
    </p>
  </div>
);

export default WelcomeMessage;
