const routes = {
  CADRE: '/startup/cadre',
  FREECAUSE: '/startup/freecause',
  GIGWAX: '/startup/gigwax',
  PRADUX: '/startup/pradux',
  SENSICAST: '/startup/sensicast',
  VERISHOP: '/startup/verishop',
  VOLPE: '/company/volpe-center',
};

export default routes;
