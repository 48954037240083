import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import ExternalLink from '@components/ExternalLink/ExternalLink';
import Spacer from '@components/Spacer/Spacer';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import * as styles from '@layouts/HomeLayout.module.scss';
import * as React from 'react';
export default {
  graphql,
  getSrc,
  ExternalLink,
  Spacer,
  VideoPlayer,
  styles,
  React
};