// extracted by mini-css-extract-plugin
export var siteHeader = "SiteHeader-module--siteHeader--1onoJ";
export var container = "SiteHeader-module--container--u-Aww";
export var homeLink = "SiteHeader-module--homeLink--2iFex";
export var wrap = "SiteHeader-module--wrap--2dNJK";
export var menuButton = "SiteHeader-module--menuButton--rhKja";
export var navItems = "SiteHeader-module--navItems--2MQn2";
export var navLinks = "SiteHeader-module--navLinks--1zJcH";
export var navLink = "SiteHeader-module--navLink--KStZZ";
export var link = "SiteHeader-module--link--OiLGJ";
export var active = "SiteHeader-module--active--1MchN";
export var fadeIn = "SiteHeader-module--fadeIn--1E4nr";
export var slideIn = "SiteHeader-module--slideIn--BOscL";
export var bgGradient = "SiteHeader-module--bgGradient--2db9s";
export var borderColorStep = "SiteHeader-module--borderColorStep--3HjMg";