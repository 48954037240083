import { Link } from 'gatsby';
import React from 'react';
import { FaAngellist, FaGoodreads, FaTwitter } from 'react-icons/fa';

import ExternalLink from '@components/ExternalLink/ExternalLink';
import Container from '@components/Grid/Container';
import * as gridStyles from '@components/Grid/Grid.module.scss';
import * as styles from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  const linkProps = { className: styles.link };
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={gridStyles.twoUp}>
          <div className={gridStyles.one}>
            <div className={styles.row}>
              <strong>startups</strong>{' '}
              <Link {...linkProps} to="/startup/cadre">
                cadre
              </Link>{' '}
              <Link {...linkProps} to="/startup/freecause">
                freecause
              </Link>{' '}
              <Link {...linkProps} to="/startup/gigwax">
                gigwax
              </Link>{' '}
              <Link {...linkProps} to="/startup/pradux">
                pradux
              </Link>{' '}
              <Link {...linkProps} to="/startup/sensicast">
                sensicast
              </Link>{' '}
              <Link {...linkProps} to="/startup/verishop">
                verishop
              </Link>{' '}
            </div>

            <div className={styles.row}>
              <strong>projects</strong>{' '}
              <Link {...linkProps} to="/project/art-vr">
                art/vr
              </Link>{' '}
              <Link {...linkProps} to="/project/build-ball">
                build ball
              </Link>
            </div>

            <div className={styles.row}>
              <strong>more</strong>{' '}
              <Link {...linkProps} to="/company/volpe-center">
                volpe center
              </Link>{' '}
            </div>
          </div>

          <div className={gridStyles.two}>
            <div className={styles.spaceBetween}>
              <div className={styles.row}>
                <strong>more links</strong>{' '}
                <Link {...linkProps} to="/contact">
                  contact
                </Link>{' '}
                <Link {...linkProps} to="/cv">
                  cv
                </Link>{' '}
                <a {...linkProps} href="/humans.txt">
                  humans.txt
                </a>
              </div>

              <div className={styles.row}>
                <strong>social links</strong> &nbsp;{' '}
                <ExternalLink href="https://angel.co/u/abailin" icon={null}>
                  <FaAngellist />
                </ExternalLink>{' '}
                <ExternalLink
                  href="https://www.goodreads.com/user/show/26503469-adam-bailin"
                  icon={null}
                >
                  <FaGoodreads />
                </ExternalLink>{' '}
                <ExternalLink href="https://twitter.com/bailinad" icon={null}>
                  <FaTwitter />
                </ExternalLink>
              </div>

              <div className={styles.row}>
                <small>
                  Thanks for reading &mdash; &copy; {year} adambailin.com
                </small>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
