import { Link } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { CVItem } from '@data/cvData';
import * as styles from './CVRow.module.scss';

const CVRow = ({ description, location, slug, title, years }: CVItem) => {
  const linkedTitle = isEmpty(slug) ? (
    title
  ) : (
    <Link className={styles.link} to={slug}>
      {title}
    </Link>
  );
  return (
    <tr>
      <td className={styles.years}>{years}</td>
      <td>
        <div className={styles.title}>{linkedTitle}</div>
        <div className={styles.description}>{description}</div>
      </td>
      <td className={styles.location}>{location}</td>
    </tr>
  );
};
export default CVRow;
