import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { ensureCanonicalUrl } from '../../lib/util';

type Breadcrumb = {
  name: string;
  slug?: string;
};

type LdListItem = {
  '@type': 'ListItem';
  item?: string;
  name: string;
  position: number;
};

// include this once (homepage)
export const PersonLinkedData = () => {
  const cestmoi = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'Adam Bailin',
    sameAs: [
      'https://angel.co/u/abailin',
      'https://twitter.com/bailinad',
      'https://www.goodreads.com/user/show/26503469-adam-bailin',
      'https://www.linkedin.com/in/adambailin/',
    ],
    url: 'https://www.adambailin.com',
  };
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(cestmoi) }}
    ></script>
  );
};

const generateBreadcrumbs = (items: Breadcrumb[]) => {
  if (isEmpty(items)) {
    return null;
  }
  const numBreadcrumbItems = items.length;

  const itemListElement = items.map((breadrumbItem, index) => {
    const isLastBreadcrumb = index + 1 === numBreadcrumbItems;
    const ld: LdListItem = {
      '@type': 'ListItem',
      position: index + 1,
      name: breadrumbItem.name,
    };
    if (!isLastBreadcrumb && breadrumbItem.slug) {
      ld.item = ensureCanonicalUrl(breadrumbItem.slug);
    }
    return ld;
  });

  const jsonLdData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
  const jsonifiedLdData = JSON.stringify(jsonLdData);
  return (
    <script key="json-ld-breadcrumbs" type="application/ld+json">
      {jsonifiedLdData}
    </script>
  );
};

type LinkedDataProps = {
  breadcrumbs?: Breadcrumb[];
};

const LinkedData = ({ breadcrumbs = [] }: LinkedDataProps) => {
  return [generateBreadcrumbs(breadcrumbs)];
};

export default LinkedData;
